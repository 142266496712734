import React, { ReactElement } from 'react'
import Error404 from 'src/contents/Error404'

import { Main } from '@/layouts'
import { makeStaticProps } from '@/lib/getStatic'
import { NextPageWithLayout } from '@/pages/_app'

const FourOhFour: NextPageWithLayout = () => <Error404 />

FourOhFour.getLayout = function getLayout(page: ReactElement) {
  return (
    <Main footer={<Error404.Footer />} header={<Error404.Header />}>
      {page}
    </Main>
  )
}

export default FourOhFour

const getStaticProps = makeStaticProps()
export { getStaticProps }
